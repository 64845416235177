.mf-edit-one-time-password,
.mf-update-one-time-password {
  margin-top: 20px;
  margin-left: 20px;

  .install-info-text,
  .password-info-text,
  .cooperative-info-text {
    font-size: 20px;
    font-weight: bold;
  }

  .pc-info-text,
  .mobile-info-text {
    font-size: 16px;
    font-weight: bold;
  }

  .wrapper-form-items {
    display: flex;
    align-items: center;
  }

  .password-field {
    height: 32px;
  }

  .submit-button {
    -webkit-appearance: none;
    margin-left: 20px;
    border-radius: 6px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
    padding: 12px 12px;
    background: #67a5da;
    color: #ffffff;
    line-height: 10px;
    opacity: 0.9;
  }

  .submit-button:hover {
    opacity: 1;
  }
}
