@import "antd/dist/antd.css";
@import "./stylesheets/one_time_passwords";

body {
  background-color: #ECEFF2;
}

// Antdスタイル拡張
.ant-form-item-with-help {
  // Formバリデーションメッセージの下側が狭いので余白を空ける
  margin-bottom: 12px;
}
